import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortDown } from '@fortawesome/free-solid-svg-icons'
import { faUserPen, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useImperativeHandle, useMemo, useRef } from 'react'
import img3 from '../../assets/images/kids/donate.jpg'
import { useState } from 'react'
import { logInfo, logWarning } from '../../utilities/log'
import axios from 'axios'
import { currencies } from '../contact_form/currency'
import ReactCountryFlag from 'react-country-flag'
import { forwardRef } from 'react'
import {
    errorNotification,
    isValidEmail,
    successNotification,
    warningNotification,
} from '../../utilities/common'
import { io } from 'socket.io-client'

export default forwardRef(function Donate({ props }, ref) {
    const donationRef = useRef()
    const [mPaymentId, setMPaymentId] = useState('')
    const [connectionOpen, setConnectionOpen] = useState(false)
    const [showCurrencies, setShowCurrencies] = useState(false)
    const [allCurrencies, setAllCurrencies] = useState([])
    const [exchangeRates, setExchangeRates] = useState([])
    const [amountInRands, setAmountInRands] = useState(0)
    const [paymentLoading, setPaymentLoading] = useState(false)
    const [paymentInitializing, setPaymentInitializing] = useState(false)
    const [amountInForeignCurrency, setAmountInForeignCurrency] = useState(0)
    const [selectedCurrency, setSelectedCurrency] = useState({
        code: 'ZAR',
        value: 1,
    })
    let [formState, setFormState] = useState({
        firstName: '',
        lastName: '',
        email: '',
        amount: '',
    })
    let socket


    useEffect(() => {
        initializePayfastEngine()
        initializeCurrencyConverter()

        // disconnect from the socket when the component unmounts
        return () => {
            if (socket) {
                socket.close()
                setConnectionOpen(false)
            }
        }
    }, [])

    // Sharing these functions to the parent element
    useImperativeHandle(ref, () => {

        return {
            displayDonationForm: () => {
                displayDonationForm()
            },

            // scroll to the donation page
            scrollIntoView: () => {
                const donateContainer = document.querySelector('.donate_container')
                donateContainer.scrollIntoView({ behavior: 'smooth' });
            }
        }
    });

    const resetFormState = () => {
        setFormState({
            firstName: '',
            lastName: '',
            email: '',
            amount: '',
        })
        setAmountInForeignCurrency(0)
    }

    /**
     * a function that allows us to use the payfast CDN.
     * by putting the script tag in our html file so we gain
     * global access to the payfast functions on the window Object
     *
     * ie: window.payfastFunctionName()
     */
    const initializePayfastEngine = () => {
        const headTag = document.querySelector('head')
        const scriptTag = document.createElement('script')
        scriptTag.defer = true
        scriptTag.type = 'text/javascript'
        scriptTag.src = 'https://www.payfast.co.za/onsite/engine.js'
        headTag.appendChild(scriptTag)
    }

    /**
     * A function used to connect to the socket server
     * if connection is successful the callback will
     * initialize the payment modal.
     *
     * @param {Function} callback
     */
    const connectToSocket = async (callback) => {
        const url = process.env.REACT_APP_SOCKET_URL
        if (url) {
            if (!connectionOpen || !socket) {
                socket = new WebSocket(url)

                // Event handler for when the connection is established
                socket.addEventListener('open', (event) => {
                    logInfo('WebSocket connection opened:', event)

                    // You can send data to the server after the connection is open
                    const message = { action: 'sendmessage' }
                    socket.send(JSON.stringify(message))
                    setConnectionOpen(true)
                })

                // Event handler for incoming messages from the server
                socket.addEventListener('message', (event) => {
                    logInfo('Message from server:', event.data)
                    messageHandler(event, callback)
                })

                // Event handler for when the connection is closed
                socket.addEventListener('close', (event) => {
                    logInfo('WebSocket connection closed:', event)
                })

                // Event handler for errors
                socket.addEventListener('error', (event) => {
                    console.error('WebSocket error:', event)
                })
            } else {
                const message = { action: 'sendmessage' }
                socket.send(JSON.stringify(message))
            }
        } else {
            logWarning('socket url not found')
        }
    }

    /**
     * a functions that handles incoming socket messages
     *
     * @param {Event} event
     * @param {Function} callback
     */
    const messageHandler = (event, callback) => {
        if (event && event.data) {
            // TODO: SWITCH ON MESSAGE TYP
            try {
                const payload = JSON.parse(event.data)

                switch (payload.type) {
                    case 'init':
                        if (payload.message?.trim()) {
                            // give the connection id to the init function
                            callback(payload.message)
                        }
                        break
                    case 'notify':
                        setPaymentLoading(false)
                        successNotification(
                            'Thank you for your support 🙏 !' + payload.message
                        )
                        resetFormState()
                        break
                    default:
                        logWarning('invalid message type ' + payload.type)
                }
            } catch (e) {
                console.error('[connectToSocket] ' + e)
            }
        }
    }

    /**
     * A function used to fetch the conversion rates for all
     * currencies in the world and store them into state.
     */
    const initializeCurrencyConverter = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/payment/rates`)
            .then((response) => {
                if (response.data.data) {
                    const rates = []
                    for (const [countryCode, value] of Object.entries(
                        response.data.data
                    )) {
                        rates.push({ code: countryCode, value: value.value })
                    }

                    setExchangeRates(rates)
                    setAllCurrencies(rates)
                }
            })
            .catch((error) => {
                console.warn('[initializeCurrencyConverter]', error)
                errorNotification('problem fetching exchange rates')
            })
    }

    /**
     * a function used to validate the required fields
     * on the payment form.
     */
    const validateRequiredFields = () => {
        if (!formState.firstName?.trim()) {
            warningNotification('please enter your name.')
            return false
        }
        if (!formState.lastName?.trim()) {
            warningNotification('please enter your last name.')
            return false
        }
        if (!formState.email?.trim()) {
            warningNotification('email is required in order to notify you.')
            return false
        }
        if (!isValidEmail(formState.email?.trim())) {
            warningNotification('enter a valid email.')
            return false
        }
        if (!formState.amount) {
            warningNotification('amount is required')
            return false
        }
        if (amountInRands < 5) {
            warningNotification('a minimum of 5 Rands can be processed')
            return false
        }

        return true
    }

    /**
     * A function used to initialize the payment in order
     * to get a uuid from the API. We will use the uuid to
     * open the payfast modal.
     *
     * @param {MouseEvent} e
     */
    const initializePayment = (e) => {
        e.preventDefault()
        sessionStorage.removeItem('pid')

        if (!validateRequiredFields()) {
            return
        }
        setPaymentInitializing(true)
        // connect to socket then initialize payment
        connectToSocket((connectionId) => {
            fetch(`${process.env.REACT_APP_API_URL}/payment/init`, {
                method: 'POST',
                body: JSON.stringify({
                    amount: amountInRands.toFixed(2).toString(),
                    item_name: 'DONATION',
                    item_description: "DONATION FROM STMARY'S WEBSITE",
                    email_address: formState.email, // 📝 email must be valid or payfast will not enable payment methods
                    name_first: formState.firstName,
                    name_last: formState.lastName,
                    con_id: connectionId,
                }),
            })
                .then((response) => {
                    return response.json()
                })
                .then((data) => {
                    if (data && data.uuid && data.id) {
                        // stands for payment id
                        sessionStorage.setItem('pid', data.id)
                        setMPaymentId(data.id)
                        openPayfastModal(data.uuid)
                    } else {
                        errorNotification('problem initializing payment', 8000)
                    }
                })
                .catch((error) => {
                    console.warn('[initializePayment]', error)
                    errorNotification('initializePayment err', error)
                })
                .finally(() => {
                    setPaymentInitializing(false)
                })
        })
    }

    /**
     * A function used to search for an exchange rate using
     * a countries name
     *
     * @param {*} event
     */
    const searchCurrencyByCountry = (event) => {
        if (event && event.target && event.target.value) {
            const searchText = event.target.value
            const matchingRates = allCurrencies.filter((currency) => {
                const countryName = currencies[currency?.code]?.name
                return countryName
                    ?.toLowerCase()
                    .includes(searchText.toLowerCase())
            })

            setExchangeRates(matchingRates)
        }
    }

    /**
     * A  function use to hide the world currency list.
     *
     * @param {Event} e
     */
    const hideCurrencies = (e) => {
        e?.preventDefault()
        setShowCurrencies(false)
    }

    /**
     * A  function use to show/hide the world currency list.
     *
     * @param {Event} e
     */
    const toggleCurrencyList = (e) => {
        e?.preventDefault()
        e.stopPropagation()
        setShowCurrencies(!showCurrencies)
    }

    /**
     *
     *
     * @param {*} event
     * @param {*} currency
     */
    const onCurrencyClick = (event, currency) => {
        event?.preventDefault()
        event.stopPropagation()

        currency = allCurrencies.find((curr) => curr.code === currency.code)

        setSelectedCurrency(currency)
        setShowCurrencies(false)

        const valueInForeignCurrency = amountInForeignCurrency / currency.value
        setAmountInForeignCurrency(amountInForeignCurrency)
        onInputChange('amount', { target: { value: amountInForeignCurrency } })
        setAmountInRands(valueInForeignCurrency)
    }

    /**
       * this function hides the donation information and shows the donation form
        */
    const displayDonationForm = () => {
        // e.preventDefault()
        document.querySelector('.donate_form').style.display = 'block'
        document.querySelector('.donation_Info_container').style.display =
            'none'
    }


    /**
     * a method used to update form state
     *
     * @param {Event} event
     */
    function onInputChange(key, event) {
        if (event.target) {
            setFormState((prevState) => ({
                ...prevState,
                [key]: event.target.value,
            }))
        } else {
            logWarning(`no target found for input ${key} `)
        }
    }

    function onAmountChange(event) {
        if (event.target && event.target.value) {
            // 📝 if its not a number ignore the value that is typed
            const value = Number(event.target.value)
            if (typeof value === 'number') {
                resetAmounts(value)
                onInputChange('amount', { target: { value: value } })
            }
        } else {
            setAmountInForeignCurrency(0)
            setAmountInRands(0)
            onInputChange('amount', { target: { value: 0 } })
        }
    }

    /**
     * a function used to update the amount in foreign currency
     * and the amount in rands
     *
     * @param {*} value
     */
    function resetAmounts(value) {
        const valueInForeignCurrency = value / selectedCurrency.value
        setAmountInForeignCurrency(value)
        setAmountInRands(valueInForeignCurrency)
    }

    /**
     * a function used to open the payfast modal then on success
     * or failure the function will report the result to the API
     *
     * ie: FrontEnd => PayFast => FrontEnd => API update
     *
     * @param {String} - unique identifier for the payment.
     */
    const openPayfastModal = (paymentUUID) => {
        window.payfast_do_onsite_payment({ uuid: paymentUUID }, (result) => {
            logInfo('payment result : ', result)
            if (result === true) {
                setPaymentLoading(true)
                //updatePaymentStatus()
            } else {
                // Payment Window Closed
                logWarning('payment window closed, after unsuccessful payment')
            }
        })
    }

    // const updatePaymentStatus = () => {
    //     const id = mPaymentId || sessionStorage.getItem('pid')
    //     if (id) {
    //         axios
    //             .put(
    //                 `${process.env.REACT_APP_API_URL}/payment/updatestatus/${id}`, //${encodeURIComponent(id)}`,
    //                 {
    //                     method: 'PUT',
    //                 }
    //             )
    //             .then((response) => {
    //                 if (response.status !== 200) {
    //                     console.warn(
    //                         '[updatePaymentStatus]',
    //                         'none 200 response'
    //                     )
    //                     errorNotification('problem updating payment status')
    //                 }
    //                 return response.json()
    //             })
    //             .catch((error) => {
    //                 console.warn('[updatePaymentStatus]', error)
    //                 errorNotification('problem updating payment status', error)
    //             })
    //     } else {
    //         warningNotification('UpdatePayment Exception')
    //     }
    // }

    return (
        <div ref={donationRef} className="donate_container row" onClick={(e) => hideCurrencies()}>
            {/* image */}
            <div className="donateImg lg-6 sm-12 xs-12">
                <img className="imagedonate" src={img3} alt="" />
            </div>

            <div className="donation_Info_container lg-5 md12 sm-12 xs-12">
                <h1> Want to make a donation ?</h1>

                <p>
                    Your generosity can transform lives at St Mary's Children's Home. By donating, you become a vital part of a community dedicated to nurturing and empowering children in need.
                    Your contribution isn't just a financial gift; it's a beacon of hope, a catalyst for change. Together, we can create brighter futures and ensure that every child feels the warmth of a caring home.
                    Your impact is immeasurable,
                    and you are truly making a difference. Donate today and be the hero that these children deserve.
                </p>

                <p className='tax-reduction'>Check if your donation qualifies for a tax deduction on the <a href="https://www.sars.gov.za/businesses-and-employers/tax-exempt-institutions/application-for-income-tax-exemption/" target='_blank'> <b> <u>SARS</u>  </b> </a> website</p>
                <p className='donate-question'>Questions or concerns should be directed to <b><i className='tax-email'>donorservices@smch.co.za</i></b></p>

                <button
                    className="makeDonationBtn"
                    onClick={() => displayDonationForm()}
                >
                    Donate
                </button>
            </div>

            {/* donate form */}
            <div className="donate_form lg-5 md-12 sm-12 xs-12">
                <form action="">
                    <h1 className="donate_heading">Make a donation</h1>

                    <div className="cardHolder_name">
                        <h4 id="reason4Name">
                            Collecting your details allows us to express our
                            gratitude, keep you informed about the impact of
                            your donation, and foster a lasting relationship.
                            Your information ensures we can stay connected,
                            share success stories, and demonstrate the tangible
                            difference your support makes in the lives of those
                            we serve.
                        </h4>

                        <div className="name-container">
                            <div className="contact-form-material-textfield lg-6 md-12 ">
                                <input
                                    value={formState.firstName}
                                    placeholder=""
                                    type="text"
                                    onChange={(e) =>
                                        onInputChange('firstName', e)
                                    }
                                />
                                <label>First Name</label>
                                <span>
                                    <FontAwesomeIcon icon={faUserPen} />
                                </span>
                            </div>

                            <div className="contact-form-material-textfield lg-6 md-12 ">
                                <input
                                    value={formState.lastName}
                                    placeholder=""
                                    type="text"
                                    onChange={(e) =>
                                        onInputChange('lastName', e)
                                    }
                                />
                                <label>Last Name</label>
                                <span>
                                    <FontAwesomeIcon icon={faUserPen} />
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="cardHolder_name">
                        <div className="contact-form-material-textfield">
                            <input
                                value={formState.email}
                                placeholder=""
                                type="email"
                                onChange={(e) => onInputChange('email', e)}
                            />
                            <label>Email</label>
                            <span>
                                <FontAwesomeIcon icon={faEnvelope} />
                            </span>
                        </div>
                    </div>

                    <div className="amount">
                        <div className="amount-container">
                            <div className="contact-form-material-textfield">
                                <input
                                    value={
                                        amountInForeignCurrency === 0
                                            ? ''
                                            : amountInForeignCurrency
                                    }
                                    type="number"
                                    onChange={(e) => onAmountChange(e)}
                                />
                                <label>Amount</label>
                            </div>
                            {
                                <div className="amount-in-rands">
                                    {amountInRands > 0 &&
                                        selectedCurrency.code !== 'ZAR'
                                        ? `(${amountInRands.toFixed(2)} Rands)`
                                        : ''}
                                </div>
                            }

                            <div
                                title="Select a currency"
                                className="currency-dropdown"
                                onClick={(e) => toggleCurrencyList(e)}
                            >
                                <div className="flag">
                                    <ReactCountryFlag
                                        countryCode={
                                            currencies[selectedCurrency?.code]
                                                ?.countryCode
                                        }
                                        style={{
                                            fontSize: '20px',
                                            lineHeight: '20px',
                                            display: 'inline-block',
                                        }}
                                    />
                                </div>

                                <div className="code">
                                    {selectedCurrency.code ?? '--'}
                                </div>

                                <span>
                                    <FontAwesomeIcon icon={faSortDown} />
                                </span>
                            </div>
                        </div>

                        <div className="custom-select-wrapper">
                            {showCurrencies && (
                                <div className="currencies">
                                    <div
                                        className="search-box"
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <input
                                            onChange={(e) =>
                                                searchCurrencyByCountry(e)
                                            }
                                            type="text"
                                            placeholder="Search by country or currency"
                                        />
                                    </div>
                                    {exchangeRates.length === 0 && (
                                        <div className="no-rates">
                                            no exchange rates found
                                        </div>
                                    )}
                                    {exchangeRates.map((currency, index) => {
                                        return (
                                            <div
                                                onClick={(e) =>
                                                    onCurrencyClick(e, currency)
                                                }
                                                className="currency"
                                                key={index}
                                            >
                                                <div className="flag">
                                                    <ReactCountryFlag
                                                        countryCode={
                                                            currencies[
                                                                currency?.code
                                                            ]?.countryCode
                                                        }
                                                        style={{
                                                            fontSize: '20px',
                                                            lineHeight: '20px',
                                                            display:
                                                                'inline-block',
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    {currencies[currency?.code]
                                                        ?.name ?? '--'}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    </div>

                    <button
                        className={
                            amountInRands > 0
                                ? 'donateBtn m-push-down'
                                : 'donateBtn'
                        }
                        onClick={(e) => initializePayment(e)}
                    >
                        {paymentInitializing ? 'Processing' : 'Continue'}
                        <span
                            className={
                                'spinner ' +
                                (paymentInitializing ? 'spinner-active' : '')
                            }
                        ></span>
                    </button>
                </form>
            </div>
        </div>
    )
})
